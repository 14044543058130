import { Input, Select } from 'antd'
import React from 'react'

interface ModifierAndDefaultProps {
  payload: any
  onModifierChange: (value: string) => void
  onDefaultValueChange: (value: string) => void
  openSelectModifier: boolean
  onDropdownVisibleChange: (visible: boolean) => void
  disableAddParam?: boolean
}

export const ModifierAndDefault: React.FC<ModifierAndDefaultProps> = ({
  payload,
  onModifierChange,
  onDefaultValueChange,
  openSelectModifier,
  onDropdownVisibleChange,
  disableAddParam,
}) => {
  if (!payload?.param?.param_value) return null

  return (
    <>
      <div className="mt-4 mb-2 font-semibold">Modifier</div>
      <Select
        disabled={disableAddParam}
        className="rounded DynamicParamsSelect"
        placeholder="Select modifier"
        style={{ width: '100%' }}
        onChange={onModifierChange}
        value={payload?.modifier ? payload?.modifier : 'Select modifier'}
        open={openSelectModifier}
        onDropdownVisibleChange={onDropdownVisibleChange}
        options={payload?.param?.format?.map((format) => ({
          label: format?.format_value,
          value: format?.format_key,
        }))}
      />

      <div className="mt-4 mb-2 font-semibold">Default value</div>
      <Input
        disabled={disableAddParam}
        className="py-2 rounded"
        placeholder={'Eg: Anh/Chị or 1000, ...'}
        value={payload?.default_value}
        onChange={(e) => onDefaultValueChange(e.target.value)}
      />
    </>
  )
} 