import { Form, Input, message } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import FormItem from '../FormItem'

export const processHTML = {
  // Quy tắc thay thế từ semantic -> legacy (hoặc ngược lại)
  replaceTags: (text, direction = 'toPlainText') => {
    const replacements = [
      { semantic: 'strong', legacy: 'b' },
      // { semantic: "em", legacy: "i" },
      { semantic: 'del', legacy: 's' },
    ]

    replacements.forEach(({ semantic, legacy }) => {
      if (direction === 'toPlainText') {
        text = text
          .replace(new RegExp(`<${semantic}>`, 'g'), `<${legacy}>`)
          .replace(new RegExp(`</${semantic}>`, 'g'), `</${legacy}>`)
      } else if (direction === 'toSemanticHTML') {
        text = text
          .replace(new RegExp(`<${legacy}>`, 'g'), `<${semantic}>`)
          .replace(new RegExp(`</${legacy}>`, 'g'), `</${semantic}>`)
      }
    })

    return text
  },

  // Chuyển từ HTML có <p> thành plain text
  toPlainText: (html) => {
    const paragraphCount = (html.match(/<p>/g) || []).length

    if (paragraphCount <= 1) {
      // Xử lý trường hợp chỉ có 1 thẻ <p>
      return html
        .replace(/<p>/g, '') // Loại bỏ thẻ <p>
        .replace(/<\/p>/g, '') // Loại bỏ thẻ </p>
        .replace(/<strong>/g, '<b>')
        .replace(/<\/strong>/g, '</b>')
        .replace(/<del>/g, '<s>')
        .replace(/<\/del>/g, '</s>')
        .trim() // Loại bỏ khoảng trắng
    }

    // Xử lý trường hợp có nhiều thẻ <p>
    return html
      .split(/<\/p>/) // Tách đoạn theo </p>
      .map(
        (chunk) => processHTML.replaceTags(chunk.replace(/<p>/g, '').trim(), 'toPlainText') // Loại bỏ <p> và thay thế thẻ
      )
      .filter((line) => line) // Loại bỏ dòng trống
      .join('\n') // Ghép các đoạn bằng \n
  },

  // Chuyển từ plain text thành HTML có <p>
  toSemanticHTML: (plainText) => {
    const lines = plainText.split(/\n/) // Tách các đoạn theo \n

    if (lines.length <= 1) {
      // Xử lý trường hợp chỉ có 1 đoạn
      return `<p>${processHTML.replaceTags(lines[0].trim(), 'toSemanticHTML')}</p>`
    }

    // Xử lý trường hợp có nhiều đoạn
    return lines
      .map((line) => `<p>${processHTML.replaceTags(line.trim(), 'toSemanticHTML')}</p>`)
      .join('') // Ghép các đoạn thành HTML
  },
}

export default function TetegramForm() {
  const [form] = Form.useForm()
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const { content } = useStoreState((state: any) => state.campaignModule)
  const MAX_CHARS = 4096

  const handleEditorChange = (event, editor) => {
    const data = editor.getData()
    if (data.length > MAX_CHARS) {
      message.warning(`Nội dung không được vượt quá ${MAX_CHARS} ký tự`)
    }
    form.setFieldsValue({ content: data })
  }

  return (
    <Form
      layout="vertical"
      name="ContentForm"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 24 }}
      requiredMark
      onFinish={(value) => {
        let contentData = form.getFieldValue('content')
        let newValue = {
          ...value,
          content: processHTML.toPlainText(contentData),
        }
        console.log({ newValue })
        message.success('Content is validated')
        setState({ key: 'isContentValidated', value: true })
        setState({ key: 'content', value: newValue })
      }}
      onFieldsChange={(field) => {
        const _field = field[0]

        // bỏ qua lần đầu `u` object
        if (typeof _field.value == 'string' && _field.name[0] == 'content') {
          setState({
            key: 'content',
            value: { ...content, [_field.name[0]]: processHTML.toPlainText(_field.value) },
          })
        }
      }}
      initialValues={
        content?.content != null
          ? {
              ...content,
              content: processHTML.toSemanticHTML(content?.content),
            }
          : content
      }
      scrollToFirstError
      form={form}
    >
      <Form.Item
        label={'Content (limit 4096 characters)'}
        name="content"
        rules={[
          { required: true, message: 'Vui lòng nhập nội dung!' },
          {
            validator: (_, value) => {
              if (value.length > MAX_CHARS) {
                return Promise.reject(`Nội dung không được vượt quá ${MAX_CHARS} ký tự`)
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <CKEditor
          className="rounded-sm"
          editor={ClassicEditor}
          data={content?.content != null ? processHTML.toSemanticHTML(content?.content) : ''}
          config={{
            toolbar: ['bold', 'italic', 'link'],
          }}
          onChange={handleEditorChange}
        />
      </Form.Item>
      <Form.Item
        label={'Images'}
        // tooltip={schema.tooltip}
        className={''}
        name={'images'}
        shouldUpdate={(prevValues, currentValues) => {
          if (prevValues['images'] !== currentValues['images']) return true
          return false
        }}
        rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}
      >
        <FormItem
          name={'images'}
          type={'images'}
          value={form.getFieldValue(content && content['images'])}
          placeholder={'Enter upload url'}
          onChange={(name, value) => {
            form.setFieldsValue({ [name]: value })
            setState({ key: 'isContentValidated', value: false })
            setState({ key: 'content', value: { ...content, [name]: value } })
          }}
          dynamicParams={content?.params}
          fileType={['image/']}
        />
      </Form.Item>
      <Form.Item
        label={'Documents'}
        // tooltip={schema.tooltip}
        className={''}
        name={'documents'}
        shouldUpdate={(prevValues, currentValues) => {
          if (prevValues['documents'] !== currentValues['documents']) return true
          return false
        }}
      >
        <FormItem
          name={'documents'}
          type={'documents'}
          value={form.getFieldValue(content && content['documents'])}
          placeholder={'Enter upload url'}
          onChange={(name, value) => {
            form.setFieldsValue({ [name]: value })
            setState({ key: 'isContentValidated', value: false })
            setState({ key: 'content', value: { ...content, [name]: value } })
          }}
          dynamicParams={content?.params}
          fileType={[]}
        />
      </Form.Item>
      <Form.Item
        label={'Videos'}
        // tooltip={schema.tooltip}
        className={''}
        name={'videos'}
        shouldUpdate={(prevValues, currentValues) => {
          if (prevValues['videos'] !== currentValues['videos']) return true
          return false
        }}
      >
        <FormItem
          name={'videos'}
          type={'videos'}
          value={form.getFieldValue(content && content['videos'])}
          placeholder={'Enter upload url'}
          onChange={(name, value) => {
            form.setFieldsValue({ [name]: value })
            setState({ key: 'isContentValidated', value: false })
            setState({ key: 'content', value: { ...content, [name]: value } })
          }}
          dynamicParams={content?.params}
          fileType={['video/']}
        />
      </Form.Item>
    </Form>
  )
}
