import { DatePicker, TimePicker } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import moment from 'moment'
import 'moment-timezone'
import React from 'react'

import { SCHEDULE_MODES, DAYS } from './constants'
import Budget from './Budget'

const DATE_FORMAT = 'DD-MM-YYYY'
const TIME_FORMAT = 'HH:mm'
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

// Disable dates before today
const disabledDate = (current) => {
  return current && current < moment().startOf('day');
};

// Disable times before current time for today
const disabledTime = (date) => {
  if (!date) return {};
  
  const now = moment();
  const selectedDate = moment(date);
  
  // If selected date is today, disable past times
  if (selectedDate.isSame(now, 'day')) {
    return {
      disabledHours: () => {
        const hours: number[] = [];
        for (let i = 0; i < now.hour(); i++) {
          hours.push(i);
        }
        return hours;
      },
      disabledMinutes: (selectedHour) => {
        if (selectedHour === now.hour()) {
          const minutes: number[] = [];
          for (let i = 0; i < now.minute(); i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      },
      disabledSeconds: (selectedHour, selectedMinute) => {
        if (selectedHour === now.hour() && selectedMinute === now.minute()) {
          const seconds: number[] = [];
          for (let i = 0; i < now.second(); i++) {
            seconds.push(i);
          }
          return seconds;
        }
        return [];
      },
    };
  }
  return {};
};

export default function Schedule() {
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)
  const { days, start_date, end_date, start_time, end_time, mode, start_at, selected_channel } =
    useStoreState((state: any) => state.campaignModule)
  const startTime = moment(`${start_date} ${start_time}`, DATE_TIME_FORMAT)
  const endTime = moment(`${end_date} ${end_time}`, DATE_TIME_FORMAT)
  const [startTimeError, setStartTimeError] = React.useState(false)
  // const [currentDate, setCurrentDate] = React.useState<any>()
  const onChangeStartDate = (value) => {
    setState({ key: 'start_date', value })
  }

  const onChangeStartTime = (value) => {
    setState({ key: 'start_time', value })
  }

  const onChangeEndDate = (value) => {
    setState({ key: 'end_date', value })
  }

  const onChangeEndTime = (value) => {
    setState({ key: 'end_time', value })
  }

  const onChangeStartAtTime = (value) => {
    setState({ key: 'start_at', value })
  }

  const onSetDays = (value: number) => {
    if (days.includes(value))
      return setState({ key: 'days', value: days.filter((day) => day !== value) })
    setState({ key: 'days', value: [...days, value] })
  }

  const handleStartDateTimeChange = (value) => {
    onChangeStartDate(moment(value).format(DATE_FORMAT))
    onChangeStartTime(moment(value).format(TIME_FORMAT))
  }

  const handleEndDateTimeChange = (value) => {
    onChangeEndDate(moment(value).format(DATE_FORMAT))
    onChangeEndTime(moment(value).format(TIME_FORMAT))
  }

  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   return current < moment().startOf('day')
  // }
  // const disabledDateTime = () => ({
  //   disabledHours: () =>
  //     currentDate == moment().toObject().date
  //       ? range(0, 24).splice(0, moment().toObject().hours)
  //       : [],
  //   disabledMinutes: () =>
  //     currentDate == moment().toObject().date ? range(0, moment().toObject().minutes) : [],
  // })
  const timezone = new Date(moment.tz().format())

  return (
    <div className="ScheduleStep">
      <div className="flex">
        {SCHEDULE_MODES.map((scheduleMode) => (
          <div
            onClick={() => setState({ key: 'mode', value: scheduleMode.value })}
            className={`${scheduleMode.value === mode ? 'toggle-selected' : ''} toggle`}
            key={scheduleMode.value}
          >
            {scheduleMode.label}
          </div>
        ))}
      </div>
      <div className="section-wrapper mt-6">
        <div className="font-semibold text-xl mb-6">Schedule</div>
        <div className="picker">
          <div>
            <h4 className="mb-2 font-semibold text-secondary_text">Start Time</h4>
            <DatePicker
              // onSelect={(value) => setCurrentDate(moment(value).format(DATE_FORMAT).split('-')[0])}
              // disabledDate={disabledDate}
              // disabledTime={disabledDateTime}
              showTime={mode === 'recurring' ? false : { format: TIME_FORMAT }}
              value={startTime.isValid() ? startTime : null}
              onChange={handleStartDateTimeChange}
              format={mode === 'recurring' ? DATE_FORMAT : DATE_TIME_FORMAT}
              placeholder="Select start time"
              disabledDate={disabledDate}
              disabledTime={disabledTime}
            />
          </div>
          {mode === 'recurring' && (
            <div>
              <h4 className="mb-2 font-semibold text-secondary_text">End Time</h4>
              <DatePicker
                value={endTime.isValid() ? endTime : null}
                onChange={handleEndDateTimeChange}
                format={DATE_FORMAT}
                placeholder="Select end time"
              />
            </div>
          )}
        </div>
        <p className="pt-2 text-gray_7" style={{ fontSize: 12 }}>
          *{timezone.toString()}
        </p>
      </div>

      {mode === 'recurring' && (
        <div className="section-wrapper mt-6">
          <div className="font-semibold text-xl mb-6">Advanced Settings</div>
          <div className="flex items-center gap-4 mb-4">
            <h4 className="font-semibold text-secondary_text">Start at</h4>
            <TimePicker
              value={start_at && moment(start_at, TIME_FORMAT)}
              onChange={(value) => onChangeStartAtTime(value?.format(TIME_FORMAT))}
              format={TIME_FORMAT}
              placeholder="Select Time"
            />
          </div>
          <div>
            <h4 className="mb-2 font-semibold text-secondary_text">Repeat on</h4>
            <div className="day-week-wrapper">
              {DAYS.map((day) => (
                <div
                  onClick={() => onSetDays(day.value)}
                  key={day.value}
                  className={`day-week ${days.includes(day.value) ? 'day-week-selected' : ''}`}
                >{`${day.label.charAt(0)}${day.label.charAt(1)}`}</div>
              ))}
            </div>
          </div>
        </div>
      )}

      {selected_channel?.type === 'facebook' && (
        <div className="section-wrapper mt-6">
          <span className="font-semibold text-lg">Budget</span>
          <Budget />
        </div>
      )}

      {/* <div className="w-1/2 bg-gray_1 p-4">
        <PreviewScreen />
      </div> */}
    </div>
  )
}
