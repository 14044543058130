import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { AutoComplete, Form, Input, message, Select, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { getMessageParams } from 'Services/store-farming'
import { getZnsTemplateByIdNew } from 'Services/new-cj'

// Types & Interfaces
export type ParamType = { parameter_code: string }
export type ParametersType = {
  group_name: string
  group_code: string
  parameters: ParamType[]
}

interface TemplateDataItem {
  key: string
  name: string
}

interface ContentType {
  template_id?: string
  template_name?: string
  template_data?: Record<string, string>
}

interface TemplateParam {
  key: string
  name: string
  value?: string
}

interface Template {
  template_id: string
  template_name: string
  params?: TemplateParam[]
  template_data?: Record<string, string>
}

interface ParameterInputProps {
  item: TemplateParam
  index: number
  onChangeDimension: (key: string, value: string) => void
  getValueContentParams: (key: string) => string
  setState: (payload: { key: string; value: any }) => void
}

interface TemplateDataInputProps {
  item: TemplateDataItem
  onChangeDimension: (key: string, value: string) => void
  getDefaultValueContentParams: (key: string) => string
  getValueContentParams: (key: string) => string
  setState: (payload: { key: string; value: any }) => void
  content: ContentType
}

// Utility Functions
const formatArrayToObject = (array: TemplateDataItem[]): Record<string, string> => {
  if (!Array.isArray(array)) return {}
  return array.reduce<Record<string, string>>((acc, { key, name }) => {
    acc[key] = name
    return acc
  }, {})
}

const formatObjectToArray = (obj: Record<string, string> | undefined): TemplateDataItem[] => {
  if (!obj || typeof obj !== 'object') return []
  return Object.entries(obj).map(([key, value]) => ({ key, name: value }))
}

// Custom Hooks
const useTemplateData = () => {
  const [templateData, setTemplateData] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTemplateData = async () => {
      setLoading(true)
      try {
        const paramsResp = await getMessageParams()
        const paramData: ParametersType[] = paramsResp.data
        const flat = paramData?.map((item) => 
          item.parameters.map((i) => i.parameter_code?.replace(/{{|}}/gi, ''))
        ) || []
        
        const listDefaultDimensions = flat.reduce<string[]>((acc, item) => 
          acc.concat(item.filter(Boolean) as string[]), []
        )
        
        setTemplateData(listDefaultDimensions)
      } catch (error) {
        console.error('Error fetching template data:', error)
      } finally {
        setLoading(false)
      }
    }
    
    fetchTemplateData()
  }, [])

  return { templateData, loading }
}

const useTemplate = (audience_id: string) => {
  const [templates, setTemplates] = useState<Template[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (!audience_id) return

    const fetchTemplates = async () => {
      setLoading(true)
      setError(null)
      
      try {
        const response = await getZnsTemplateByIdNew()
        setTemplates(response?.data || [])
      } catch (error) {
        console.error('Error fetching templates:', error)
        setError('Failed to load templates')
        setTemplates([])
      } finally {
        setLoading(false)
      }
    }

    fetchTemplates()
  }, [audience_id])

  return { templates, loading, error }
}

// Components
const TemplateNameDisplay: React.FC<{ templateName?: string }> = React.memo(({ templateName }) => (
  <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }} className="px-4 py-2 rounded">
    {templateName || ''}
  </div>
))

TemplateNameDisplay.displayName = 'TemplateNameDisplay'

const ParameterInput: React.FC<ParameterInputProps> = React.memo(({ 
  item, 
  index, 
  onChangeDimension, 
  getValueContentParams, 
  setState 
}) => {
  const { selected_dynamic_param } = useStoreState((state) => state.campaignModule)

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeDimension(item?.key, e.target.value)
    setState({
      key: 'selected_dynamic_param',
      value: {
        key: item?.key,
        value: e.target.value,
        position: e?.target?.selectionStart,
        ZNSKey: item?.name,
        ZNSValue: item?.value,
      },
    })
  }, [item?.key, item?.name, item?.value, onChangeDimension, setState])

  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setState({
      key: 'selected_dynamic_param',
      value: {
        key: item?.key,
        value: e.target.value,
        position: e?.target?.selectionStart,
        ZNSKey: item?.name,
        ZNSValue: item?.value,
      },
    })
  }, [item?.key, item?.name, item?.value, setState])

  const handleClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    setState({
      key: 'selected_dynamic_param',
      value: {
        key: item?.key,
        value: e.currentTarget.value,
        position: e.currentTarget.selectionStart,
        ZNSKey: item?.name,
        ZNSValue: item?.value,
      },
    })
  }, [item?.key, item?.name, item?.value, setState])

  const paramValue = getValueContentParams(item?.key)

  return (
    <Form.Item key={item?.name} label={`parameter ${index + 1}`}>
      <Input disabled value={item?.name || ''} />
      <Input
        onChange={handleChange}
        value={paramValue}
        onFocus={handleFocus}
        onClick={handleClick}
      />
    </Form.Item>
  )
})

ParameterInput.displayName = 'ParameterInput'

const TemplateDataInput: React.FC<TemplateDataInputProps> = React.memo(({ 
  item, 
  onChangeDimension, 
  getDefaultValueContentParams, 
  getValueContentParams, 
  setState,
}) => {
  // Add hidden fields check
  const hiddenFields = ["krb_campaign_id", "krb_cj_id", "action_id"]
  if (hiddenFields.includes(item?.key)) {
    // Return a hidden Form.Item to maintain form state
    return (
      <Form.Item 
        key={item?.key} 
        name={item?.key}
        hidden
      >
        <Input type="hidden" />
      </Form.Item>
    )
  }

  const updateSelectedDynamicParam = useCallback((value: string, position: number) => {
    setState({
      key: 'selected_dynamic_param',
      value: {
        key: item?.key,
        value,
        position,
        ZNSKey: item?.key,
        ZNSValue: value,
      },
    })
  }, [item?.key, setState])

  const handleValueChange = useCallback((value: string, position: number = 0) => {
    onChangeDimension(item?.key, value)
    updateSelectedDynamicParam(value, position)
  }, [item?.key, onChangeDimension, updateSelectedDynamicParam])

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleValueChange(e.target.value, e.target.selectionStart || 0)
  }, [handleValueChange])

  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    updateSelectedDynamicParam(e.target.value, e.target.selectionStart || 0)
  }, [updateSelectedDynamicParam])

  const handleClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    updateSelectedDynamicParam(e.currentTarget.value, e.currentTarget.selectionStart || 0)
  }, [updateSelectedDynamicParam])

  const handleAutoCompleteSelect = useCallback((value: string) => {
    handleValueChange(value, 0)
  }, [handleValueChange])

  const handleAutoCompleteChange = useCallback((value: string) => {
    handleValueChange(value, 0)
  }, [handleValueChange])

  // Tùy chọn 1: Tự quản lý không dùng Form.Item name
  const defaultValue = getDefaultValueContentParams(item?.key)
  const inputValue = getValueContentParams(item?.key)

  return (
    <Form.Item 
      key={item?.key} 
      label={item?.key}
    >
      <AutoComplete
        placeholder="Input value or select from list"
        onSelect={handleAutoCompleteSelect}
        onBlur={handleFocus}
        defaultValue={defaultValue}
        onChange={handleAutoCompleteChange}
        value={inputValue}
      >
        <Input
          onChange={handleInputChange}
          value={inputValue}
          onFocus={handleFocus}
          onClick={handleClick}
        />
      </AutoComplete>
    </Form.Item>
  )
})

TemplateDataInput.displayName = 'TemplateDataInput'

// Main Component
const FormItemZNS: React.FC = () => {
  
  // Form and State Setup
  const [form] = Form.useForm()
  const { content, audience_id, selected_dynamic_param } = useStoreState(
    (state) => state.campaignModule
  )
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
  const [loadingDimension, setLoadingDimension] = useState(false)
  
  // Custom Hooks
  const { templateData, loading: loadingTemplateData } = useTemplateData()
  const { templates, loading: loadingTemplates, error: templatesError } = useTemplate(audience_id)

  // Stabilize setState reference to avoid dependency issues
  const setContentState = useCallback((value: any) => {
    setState({ key: 'content', value })
  }, [setState])

  const setIsContentValidatedState = useCallback((value: boolean) => {
    setState({ key: 'isContentValidated', value })
  }, [setState])

  // Memoized Values
  const selectedTemplate = useMemo(() => {
    return templates.find(template => template.template_id === selectedTemplateId)
  }, [templates, selectedTemplateId])
  
  const templateOptions = useMemo(() => {
    return templates.map(item => ({
      value: item.template_id,
      label: item.template_name,
    }))
  }, [templates])

  const templateDataEntries = useMemo(() => {
    if (!selectedTemplate?.template_data) return []
    
    return Object.entries(selectedTemplate.template_data)
      .map(([key, value]) => ({ key, name: value }))
  }, [selectedTemplate])

  // Form initial values - moved before any conditional returns
  const initialValues = useMemo(() => {
    // Initialize all template data fields with empty string
    const templateDataValues = templateDataEntries.reduce((acc, { key }) => {
      acc[key] = ''
      return acc
    }, {} as Record<string, string>)

    return {
      template_id: content?.template_id || templates[0]?.template_id,
      template_name: content?.template_name || templates[0]?.template_name,
      ...templateDataValues
    }
  }, [content?.template_id, content?.template_name, templates, templateDataEntries])

  // Event Handlers
  const onFinish = useCallback(() => {
    // Get form values for template_id and template_name
    const formValues = form.getFieldsValue(['template_id', 'template_name'])
    
    // Đảm bảo template_data chứa tất cả các field từ selectedTemplate
    const completeTemplateData = templateDataEntries.reduce((acc, { key }) => {
      // Ưu tiên giá trị đã có trong content.template_data
      acc[key] = content?.template_data?.[key] || ''
      return acc
    }, {} as Record<string, string>)
    
    // Update content với form values và template_data đầy đủ
    const updatedContent = {
      ...content,
      template_id: formValues.template_id,
      template_name: formValues.template_name,
      template_data: completeTemplateData
    }
    
    setContentState(updatedContent)

    message.success('Content is validated')
    console.log(JSON.stringify(updatedContent))
    setIsContentValidatedState(true)
  }, [content, form, setContentState, setIsContentValidatedState, templateDataEntries])

  const onChangeValue = useCallback((name: string, value?: string) => {
    setSelectedTemplateId(value)
    const selectedTemplate = templates.find(t => t.template_id === value)
    
    // Set both template_id and template_name in form
    form.setFieldsValue({ 
      [name]: value,
      template_name: selectedTemplate?.template_name
    })
    
    setIsContentValidatedState(false)
    
    // Đảm bảo template_data chứa tất cả field từ template được chọn
    let templateDataValues = {}
    
    if (selectedTemplate?.template_data) {
      // Dùng Object.keys để lấy tất cả keys từ template_data của template được chọn
      templateDataValues = Object.keys(selectedTemplate.template_data).reduce((acc, key) => {
        acc[key] = '' // Initialize with empty string
        return acc
      }, {} as Record<string, string>)
    }
    
    setContentState({ 
      ...content, 
      [name]: value,
      template_name: selectedTemplate?.template_name,
      template_data: templateDataValues 
    })
  }, [content, form, setContentState, setIsContentValidatedState, templates])

  const onChangeDimension = useCallback((name: string, value: string) => {
    form.setFieldsValue({ [name]: value })
    
    const templateDataArray = formatObjectToArray(content?.template_data || {})
    const existingItemIndex = templateDataArray.findIndex((item) => item.key === name)
    
    if (existingItemIndex !== -1) {
      templateDataArray[existingItemIndex] = { key: name, name: value }
    } else {
      templateDataArray.push({ key: name, name: value })
    }

    setContentState({ ...content, template_data: formatArrayToObject(templateDataArray) })
  }, [content, form, setContentState])

  // Helper Functions
  const getValueContentParams = useCallback((name: string): string => {
    if (!content?.template_data) return ''
    const item = formatObjectToArray(content.template_data).find(item => item.key === name)
    return item?.name || ''
  }, [content?.template_data])

  const getDefaultValueContentParams = useCallback((name: string): string => {
    if (!content?.template_data || typeof content.template_data !== 'object') return ''
    const item = formatObjectToArray(content.template_data).find(item => item.key === name)
    return item?.name || ''
  }, [content?.template_data])

  // Define all memoized props before any conditional returns
  const parameterInputProps = useCallback((item: any, index: number) => ({
    key: item.name || index,
    item,
    index,
    onChangeDimension,
    getValueContentParams,
    setState: (payload: { key: string; value: any }) => {
      setState(payload)
    },
    content
  }), [onChangeDimension, getValueContentParams, setState, content])

  const templateDataInputProps = useCallback((item: any) => ({
    key: item.key,
    item,
    onChangeDimension,
    getDefaultValueContentParams,
    getValueContentParams,
    setState: (payload: { key: string; value: any }) => {
      setState(payload)
    },
    content
  }), [
    onChangeDimension, 
    getDefaultValueContentParams, 
    getValueContentParams, 
    setState, 
    content
  ]);

  // Handle template selection when templates load
  useEffect(() => {
    if (!templates.length) return;
    
    if (content?.template_id) {
      if (selectedTemplateId !== content.template_id) {
        setSelectedTemplateId(content.template_id);
        form.setFieldsValue({ template_id: content.template_id });
      }
    } else if (!selectedTemplateId && templates.length > 0) {
      const firstTemplateId = templates[0].template_id;
      setSelectedTemplateId(firstTemplateId);
      form.setFieldsValue({ template_id: firstTemplateId });
      setContentState({
        ...content,
        template_id: firstTemplateId,
        template_name: templates[0].template_name,
      });
    }
  }, [templates, content?.template_id, selectedTemplateId, form, setContentState, content]); 

  // Update template name when selected template ID changes
  useEffect(() => {
    if (!selectedTemplate || !selectedTemplateId) return;
    
    // Only update if the name actually changed to avoid loops
    if (content?.template_name !== selectedTemplate.template_name) {
      setContentState({
        ...content,
        template_name: selectedTemplate.template_name,
      });
    }
  }, [selectedTemplate, selectedTemplateId, content, setContentState]);

  // Add a debug effect to detect too many re-renders
  useEffect(() => {
    console.log('FormItemZNS rendered', { 
      selectedTemplateId, 
      contentTemplateId: content?.template_id,
      contentTemplateName: content?.template_name
    });
  }, [selectedTemplateId, content?.template_id, content?.template_name]);
  
  // Loading State and Error Handling
  if (loadingTemplates) {
    return <Skeleton active />
  }

  if (templatesError) {
    return <div className="error-message">{templatesError}</div>
  }

  // Render
  return (
    <div className="form-item-zns">
      <Form
        layout="vertical"
        name="ContentForm"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        requiredMark
        onFinish={onFinish}
        initialValues={initialValues}
        scrollToFirstError
        form={form}
      >
        {/* Template Selection */}
        <Form.Item 
          key="template_id" 
          label="Template ID" 
          name="template_id"
        >
          <Select
            onChange={(value: string) => onChangeValue('template_id', value)}
            options={templateOptions}
          >
            {templates.map((item) => (
              <Select.Option key={item.template_id} value={item.template_id}>
                {item.template_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* Template Name Display */}
        <Form.Item 
          key="template_name" 
          label="Template Name"
          name="template_name"
          initialValue={content?.template_name}
        >
          <TemplateNameDisplay templateName={content?.template_name} />
        </Form.Item>

        {/* Template Data Section */}
        {loadingDimension ? (
          <Skeleton active />
        ) : (
          templateDataEntries.map((item) => (
            <TemplateDataInput {...templateDataInputProps(item)} />
          ))
        )}
      </Form>
    </div>
  )
}

export default React.memo(FormItemZNS)
