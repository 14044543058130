import React, { useState, useEffect, useRef } from 'react'
import { Input, Upload, message } from 'antd'
import { FileImageOutlined } from '@ant-design/icons'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { UploadFile, UploadChangeParam, UploadFileStatus } from 'antd/lib/upload/interface.d'
import { compact } from 'lodash'

import { uploadProductImage } from 'Services/products'
import './DynamicContent.scss'
import { useStoreActions, useStoreState } from 'easy-peasy'

export type UploadProps = {
  onChange: (images: string[] | string | undefined) => void
  value: string[] | string | undefined
  fileType: string[]
  fileCount?: number
  fieldName?: string
  multiple?: boolean
}

const isValidFile = (validMimeTypes: string[], fileType: string) => {
  return validMimeTypes.some((type) => fileType.includes(type))
}

const UploadField: React.FC<UploadProps> = ({
  onChange: setFiles,
  value,
  fileCount = 10,
  fileType,
  fieldName,
  multiple = true,
}) => {
  const { selected_dynamic_param, content } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((state: any) => state.campaignModule)
  const [inputHasValue, setInputHasValue] = useState(false)
  const firstRun = useRef(true)

  const initializeFileList = () => {
    // Nếu không có value, trả về mảng rỗng
    if (!value) return []

    // Single upload (multiple = false)
    if (!multiple) {
      // Value phải là string khi multiple = false
      if (Array.isArray(value)) {
        console.warn('Value should be string when multiple is false')
        return []
      }

      return [
        {
          uid: value,
          name: value,
          status: 'done' as UploadFileStatus,
          url: value,
        },
      ]
    }

    // Multiple upload (multiple = true)
    // Value phải là array khi multiple = true
    if (!Array.isArray(value)) {
      console.warn('Value should be array when multiple is true')
      return []
    }

    return value.map((file) => ({
      uid: file,
      name: file,
      status: 'done' as UploadFileStatus,
      url: file,
    }))
  }

  const [fileList, setFileList] = useState<UploadFile[]>(initializeFileList())

  const handleUploadFile = async (info: UploadRequestOption) => {
    try {
      const file: any = info.file
      if (fileType.length > 0) {
        if (!isValidFile(fileType, file.type)) {
          throw new Error(`Only ${fileType.join(', ')} files are allowed`)
        }
      }

      const resp = await uploadProductImage({
        image: info.file,
        config: {
          onUploadProgress: (event) => {
            info.onProgress &&
              info.onProgress({ ...event, percent: (event.loaded / event.total) * 100 })
          },
        },
      })
      const xhr = new XMLHttpRequest()
      info.onSuccess && info.onSuccess(resp.data[0].url, xhr)
    } catch (err: any) {
      err && message.error(err.message || 'Cannot upload file, try another one')
      info.onError &&
        info.onError({
          name: 'error',
          message: err.message || 'Cannot upload file, try another one',
        })
    }
  }

  const onChange = (info: UploadChangeParam) => {
    info.fileList.forEach((file: UploadFile) => {
      if (file.response) file.url = file.response
      if (file.status === 'error') file.thumbUrl = ''
      if (file.status == null) file.status = 'error'
    })
    setFileList(info.fileList)
  }

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    if (multiple) {
      setFiles(compact(fileList.map((file) => file.url)))
    } else {
      const url = fileList[0]?.url
      setFiles(url)
    }
  }, [fileList, fileCount, multiple])

  const renderUploader = () => {
    return (
      <Upload.Dragger
        fileList={fileList}
        defaultFileList={fileList}
        customRequest={handleUploadFile}
        onChange={onChange}
        listType="picture-card"
        multiple={multiple}
        maxCount={multiple ? fileCount : 1}
        beforeUpload={(file) => {
          const isLt100M = file.size / 1024 / 1024 < 100 // Giới hạn 100MB
          if (!isLt100M) {
            message.error('File quá lớn, vui lòng tải lên file nhỏ hơn 100MB')
          }
          return isLt100M
        }}
      >
        <p className="ant-upload-drag-icon">
          <FileImageOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload or Input below</p>
      </Upload.Dragger>
    )
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    if (multiple) {
      setFiles(inputValue ? inputValue.split(',').map((v) => v.trim()) : [])
    } else {
      setFiles(inputValue || undefined)
    }
    setInputHasValue(!!inputValue)
    setState({
      key: 'selected_dynamic_param',
      value: { key: fieldName, value: inputValue, position: e?.target?.selectionStart },
    })
  }

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    setState({
      key: 'selected_dynamic_param',
      value: {
        ...selected_dynamic_param,
        position: e.currentTarget.selectionStart,
      },
    })
  }

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setState({
      key: 'selected_dynamic_param',
      value: {
        key: fieldName,
        value: e.currentTarget.value,
        position: e.currentTarget.selectionStart,
      },
    })
  }

  return (
    <div className="CampaignEmail-ImageUpload">
      <div style={{ display: inputHasValue ? 'none' : 'block' }}>{renderUploader()}</div>
      <div>
        <Input
          placeholder="Enter URL"
          value={content?.[fieldName as string] || value || ''}
          onChange={handleInputChange}
          onClick={handleInputClick}
          onFocus={handleInputFocus}
        />
      </div>
    </div>
  )
}

export default UploadField
