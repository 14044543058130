import { Button, Drawer, message } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import React, { useEffect, useState, useCallback } from 'react'
import { RiCloseLine } from 'react-icons/ri'
import './index.scss'
import { addTextAtPosition, formatArrayToObject, formatObjectToArray } from './constants'
import SegmentScreen from './DinamicDimensions/Customer.SegmentScreen'
import {
  getAllDimension,
  getCampaignDynamicParams,
  getDimensionDetail,
} from 'Services/campaign-dynamic-params'
import { formatValueType } from 'Pages/Audiences/Detail/helpers'
import { ParameterSelect } from './components/ParameterSelect'
import { ModifierAndDefault } from './components/ModifierAndDefault'

export type DynamicParamPayload = {
  param: {
    param_value: string
    param_format: string
    param_group: string
    format: any
  }
  modifier?: string
  default_value?: string
}

export default function DynamicParams({
  extraParams,
  disableAddParam,
}: {
  extraParams: any
  disableAddParam?: boolean
}) {
  const {
    content,
    selected_dynamic_param,
    audience_id,
    id,
    dynamic_param_dimensions,
    toggleDynamicParam,
    segmentation_type,
  } = useStoreState((state: any) => state.campaignModule)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)

  const [openDrawer, setOpenDrawer] = useState(false)
  const [initialValues, setInitialValues] = useState<any>({})
  const [searchTerm, setSearchTerm] = useState('')
  const [openSelectParam, setOpenSelectParam] = useState(false)
  const [openSelectModifier, setOpenSelectModifier] = useState(false)
  const [payload, setPayload] = useState<DynamicParamPayload>({
    param: {
      param_value: '',
      param_format: '',
      param_group: '',
      format: [],
    },
    modifier: '',
    default_value: '',
  })
  const [dynamicParams, setDynamicParams] = useState<any>([])
  const [dimensions, setDimensions] = useState<any>([])
  const [dimensionDetail, setDimensionDetail] = useState<any>(null)
  const [segmentValues, setSegmentValues] = useState<any>({
    audience_Type: 'audience',
    type: '',
    value: '',
    count: '',
    operator: '',
    time_operator: '',
    time_value: '',
  })

  const handleGetCampaignDynamicParams = useCallback(async () => {
    const resp = await getCampaignDynamicParams(audience_id, id)
    setDynamicParams(resp?.data)
  }, [audience_id, id])

  const handleGetAllDimension = useCallback(async () => {
    const resp = await getAllDimension()
    setDimensions(resp?.data)
  }, [])

  const handleGetDimensionDetail = useCallback(async (type) => {
    const resp = await getDimensionDetail(type)
    setDimensionDetail(resp?.data)
  }, [])

  React.useEffect(() => {
    handleGetCampaignDynamicParams()
    handleGetAllDimension()
  }, [handleGetCampaignDynamicParams, handleGetAllDimension])

  useEffect(() => {
    if (dynamic_param_dimensions) {
      const parsedDimensions = JSON?.parse(dynamic_param_dimensions || '[]')
      setInitialValues(parsedDimensions)
      handleGetDimensionDetail(parsedDimensions?.[0]?.type)
    }
  }, [dynamic_param_dimensions, handleGetDimensionDetail])

  useEffect(() => {
    if (dimensionDetail) {
      const parsedDimensions = JSON?.parse(dynamic_param_dimensions || '[]')
      const ifExist = dynamicParams?.find(
        (item) => item?.group_code === dimensionDetail?.group_code
      )
      
      if (ifExist) {
        setDynamicParams(
          dynamicParams?.map((item) => {
            if (item?.group_code === dimensionDetail?.group_code) {
              return {
                ...item,
                group_condition: parsedDimensions?.[0],
              }
            }
            return item
          })
        )
      } else {
        setDynamicParams([
          ...dynamicParams,
          {
            ...dimensionDetail,
            group_condition: parsedDimensions?.[0],
          },
        ])
      }
    }
  }, [dimensionDetail, toggleDynamicParam, dynamic_param_dimensions])

  const clearPayload = useCallback(() => {
    setPayload({
      param: {
        param_value: '',
        param_format: '',
        param_group: '',
        format: [],
      },
    })
  }, [])

  const generateParamValue = useCallback(() => {
    if (!payload?.param?.param_value) return ''
    
    const paramGroup = JSON?.parse(dynamic_param_dimensions || '[]')?.[0]?.type
    const groupPrefix = payload?.param?.param_group === paramGroup ? `${payload?.param?.param_group}_` : ''
    const modifier = payload.modifier ? `|${payload.modifier}` : ''
    const defaultValue = payload.default_value ? `|${payload.default_value}` : ''
    
    return `{{${groupPrefix}${payload.param.param_value}${modifier}${defaultValue}}}`
  }, [payload, dynamic_param_dimensions])

  const handleInsert = useCallback(() => {
    if (!payload?.param?.param_value) {
      message.error('Please select parameter', 2)
      return
    }

    const paramValue = generateParamValue()
    const { key, position, ZNSKey, ZNSValue, ZNSSource } = selected_dynamic_param

    // Case 1: Direct content update
    if (!ZNSKey) {
      setState({
        key: 'content',
        value: {
          ...content,
          [key]: addTextAtPosition(
            content?.[key] || ZNSValue || '',
            position,
            paramValue
          )?.trim(),
        },
      })
      // Preserve selected_dynamic_param
      setState({
        key: 'selected_dynamic_param',
        value: {
          ...selected_dynamic_param,
          position: position + paramValue.length
        }
      })
      return
    }

    // Case 2: Template data update (CJ source)
      const templateDataArray = formatObjectToArray(content?.template_data || {})
      const existingItemIndex = templateDataArray.findIndex(item => item.key === ZNSKey)
      
      const newItem = {
        key: ZNSKey,
        name: addTextAtPosition(
          existingItemIndex !== -1 ? templateDataArray[existingItemIndex]?.name : ZNSValue,
          position,
          paramValue
        )?.trim(),
      }

      if (existingItemIndex !== -1) {
        templateDataArray[existingItemIndex] = newItem
      } else {
        templateDataArray.push(newItem)
      }

      setState({
        key: 'content',
        value: { ...content, template_data: formatArrayToObject(templateDataArray) },
      })
      // Preserve selected_dynamic_param
      setState({
        key: 'selected_dynamic_param',
        value: {
          ...selected_dynamic_param,
          position: position + paramValue.length
        }
      })
      
  }, [content, selected_dynamic_param, payload, generateParamValue, setState])

  const handleAddParameter = useCallback(() => {
    handleGetDimensionDetail(segmentValues?.type)
    setOpenDrawer(false)
    
    let str = segmentValues?.value
    const cleanedStr = str.replace(/[\[\]\s"]/g, '')
    const result = cleanedStr.split(',').join(';')
    
    setState({
      key: 'dynamic_param_dimensions',
      value: JSON?.stringify([
        {
          ...segmentValues,
          segmentation_type: segmentation_type || null,
          value: result,
          time_value: formatValueType(segmentValues.time_value),
        },
      ]),
    })
    
    message.success('Add new parameter successfully', 2)
  }, [segmentValues, segmentation_type, handleGetDimensionDetail, setState])

  return (
    <div className="p-4 sticky top-0">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-bold">Personalize</h3>
        <RiCloseLine
          className="cursor-pointer"
          size={20}
          onClick={() => setState({ key: 'toggleDynamicParam', value: false })}
        />
      </div>

      <div className="mt-4 mb-2 font-semibold">Parameter</div>
      <ParameterSelect
        open={openSelectParam}
        onDropdownVisibleChange={setOpenSelectParam}
        searchTerm={searchTerm}
        onSearch={setSearchTerm}
        dynamicParams={dynamicParams}
        extraParams={extraParams}
        payload={payload}
        onSelect={(param, group) => {
          setPayload({
            ...payload,
            param: {
              param_value: param?.parameter_code,
              param_format: param?.format,
              param_group: group?.group_code,
              format: param?.format,
            },
            modifier: '',
            default_value: '',
          })
          setOpenSelectParam(false)
        }}
        onClear={clearPayload}
        onEditGroup={(group) => {
          if (group?.group_condition) {
            setInitialValues(group?.group_condition)
          }
          setOpenDrawer(true)
          setOpenSelectModifier(false)
        }}
        disableAddParam={disableAddParam}
        onAddParam={() => setOpenDrawer(true)}
      />

      <ModifierAndDefault
        payload={payload}
        onModifierChange={(value) => setPayload({ ...payload, modifier: value })}
        onDefaultValueChange={(value) => setPayload({ ...payload, default_value: value })}
        openSelectModifier={openSelectModifier}
        onDropdownVisibleChange={setOpenSelectModifier}
        disableAddParam={disableAddParam}
      />

      <Button
        className="w-full mt-4 border border-solid border-gray_5 rounded !text-black !font-semibold"
        onClick={handleInsert}
      >
        <p className="font-semibold">Insert</p>
      </Button>

      <Drawer
        visible={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
          handleGetDimensionDetail(segmentValues?.type)
        }}
        title="Add new parameter"
        width={980}
        className="DynamicParamsDrawer"
        footer={
          <Button
            className="border text-right border-solid border-gray_5 rounded !text-black !font-semibold"
            onClick={handleAddParameter}
          >
            Add
          </Button>
        }
      >
        <div>
          <SegmentScreen
            segments={dimensions}
            initialValues={initialValues}
            segmentValues={segmentValues}
            setSegmentValues={setSegmentValues}
            isEditing={true}
          />
        </div>
      </Drawer>
    </div>
  )
}
