import { Select, Tooltip } from 'antd'
import React from 'react'
import { RiInformationLine, RiPencilLine } from 'react-icons/ri'

interface ParameterSelectProps {
  open: boolean
  onDropdownVisibleChange: (visible: boolean) => void
  searchTerm: string
  onSearch: (value: string) => void
  dynamicParams: any[]
  extraParams: any[]
  payload: any
  onSelect: (param: any, group: any) => void
  onClear: () => void
  onEditGroup: (group: any) => void
  disableAddParam?: boolean
  onAddParam: () => void
}

export const ParameterSelect: React.FC<ParameterSelectProps> = ({
  open,
  onDropdownVisibleChange,
  searchTerm,
  onSearch,
  dynamicParams,
  extraParams,
  payload,
  onSelect,
  onClear,
  onEditGroup,
  disableAddParam,
  onAddParam,
}) => {
  return (
    <Select
      key="parameter"
      className="rounded DynamicParamsSelect"
      placeholder="Select parameter"
      style={{ width: '100%' }}
      open={open}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onSearch={onSearch}
      allowClear
      onClear={onClear}
      showSearch
      value={payload?.param?.param_value ? payload?.param?.param_value : 'Select parameter'}
      dropdownRender={(menu) => (
        <div key={menu.key}>
          <div className="hidden">{menu}</div>
          <div className="text-black" style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {dynamicParams?.concat(extraParams)?.map((group) => (
              <div
                key={group.group_name}
                className="border-b"
                style={{
                  display: group?.parameters?.filter((param) =>
                    param?.parameter_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
                  ).length
                    ? 'block'
                    : 'none',
                }}
              >
                <div className="flex justify-between px-4 items-center">
                  <div className="h-10 flex items-center">{group.group_name}</div>
                  <div
                    className="gap-1 items-center"
                    style={{
                      display: group?.group_condition || group?.segmentation_type ? 'flex' : 'none',
                    }}
                  >
                    <Tooltip
                      title={
                        group?.group_condition && (
                          <span>
                            {(() => {
                              const condition = group.group_condition
                              return [
                                `${condition.type} ${condition.operator} ${condition.value}`,
                                condition.time_operator?.name,
                                condition.time_value,
                                condition.count,
                              ]
                                .filter(Boolean)
                                .join(' ')
                            })()}
                          </span>
                        )
                      }
                    >
                      <RiInformationLine />
                    </Tooltip>

                    <RiPencilLine
                      className="cursor-pointer text-blue-500"
                      onClick={() => onEditGroup(group)}
                    />
                  </div>
                </div>
                <div className="flex gap-1 flex-col w-fit">
                  {group?.parameters
                    ?.filter((param) =>
                      param?.parameter_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
                    )
                    .map((param) => (
                      <div
                        className={`px-8 h-10 truncate w-fit flex items-center hover:bg-gray-100 text-gray-500 cursor-pointer ${
                          payload?.param?.param_value === param.parameter_code ? 'bg-gray_1' : ''
                        }`}
                        key={param.parameter_code}
                        onClick={() => onSelect(param, group)}
                      >
                        <Tooltip title={param?.parameter_code}>
                          <span className="truncate">{param?.parameter_code}</span>
                        </Tooltip>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
          {!disableAddParam && (
            <div onClick={onAddParam} className="p-4 border-t cursor-pointer text-black">
              + Add Parameter
            </div>
          )}
        </div>
      )}
    />
  )
} 